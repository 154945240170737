import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/frontend/chameleon-docs/src/components/Layout/DefaultLayout.js";
import FigmaEmbed from 'components/FigmaEmbed';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2 {...{
      "id": "usage",
      "style": {
        "position": "relative"
      }
    }}>{`Usage`}</h2>
    <p>{`Chameleon icons should be used to communicate action, draw attention and convey
more meaning. They should, when possible, always be paired with a text label.`}</p>
    <h2 {...{
      "id": "sizes",
      "style": {
        "position": "relative"
      }
    }}>{`Sizes`}</h2>
    <p>{`All icons are available in sets of four different sizes, where the `}<strong parentName="p">{`default`}</strong>{`
size is 24px x 24px.`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Small`}</strong>{`: 16px x 16px`}</li>
      <li parentName="ul"><strong parentName="li">{`Medium`}</strong>{`: 24px x 24px`}</li>
      <li parentName="ul"><strong parentName="li">{`Large`}</strong>{`: 28px x 28px`}</li>
      <li parentName="ul"><strong parentName="li">{`xLarge`}</strong>{`: 32px x 32px`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "componentName=Icon, icons=Brand wrap=flex",
        "componentName": "Icon,",
        "icons": "Brand",
        "wrap": "flex"
      }}>{`<Icon as={Brand} size="small" color="primaryBase" />
<Icon as={Brand} size="medium" color="primaryBase" />
<Icon as={Brand} size="large"  color="primaryBase" />
<Icon as={Brand} size="xLarge" color="primaryBase" />
`}</code></pre>
    <h2 {...{
      "id": "color",
      "style": {
        "position": "relative"
      }
    }}>{`Color`}</h2>
    <p>{`Icons have access to the full Chameleon color palette, but need to pass a color
contrast ratio of 4.5:1.`}</p>
    <FigmaEmbed url="https://www.figma.com/file/eVuNlIBZh5wbDiM4blDaEb/Chameleon-Best-Practices?node-id=408%3A42" mdxType="FigmaEmbed" />
    <h2 {...{
      "id": "guidelines",
      "style": {
        "position": "relative"
      }
    }}>{`Guidelines`}</h2>
    <h3 {...{
      "id": "fill-vs-outlined",
      "style": {
        "position": "relative"
      }
    }}>{`Fill vs. outlined`}</h3>
    <p>{`Switching between icon styles should always be intentionally. For example,
indicating an active state by choosing for a filled icon and a deactivated state
with the outlined style. We also recommend not to mix up styles when grouping
icons.`}</p>
    <FigmaEmbed url="https://www.figma.com/file/eVuNlIBZh5wbDiM4blDaEb/Chameleon-Best-Practices?node-id=399%3A49" mdxType="FigmaEmbed" />
    <h3 {...{
      "id": "tooltips",
      "style": {
        "position": "relative"
      }
    }}>{`Tooltips`}</h3>
    <p>{`Whenever you're desging icons without text labels, they should always be paired
with a `}<a parentName="p" {...{
        "href": "/components/tooltip/design"
      }}>{`Tooltip`}</a>{` component to provide the user more
context and meaning.`}</p>
    <FigmaEmbed url="https://www.figma.com/file/eVuNlIBZh5wbDiM4blDaEb/Chameleon-Best-Practices?node-id=408%3A0" mdxType="FigmaEmbed" />
    <h3 {...{
      "id": "when-designing-new-icons",
      "style": {
        "position": "relative"
      }
    }}>{`When designing new icons`}</h3>
    <p>{`Icons should always be designed on a 24px art board with a padding of 2px.`}</p>
    <FigmaEmbed url="https://www.figma.com/file/eVuNlIBZh5wbDiM4blDaEb/Chameleon-Best-Practices?node-id=337%3A0" mdxType="FigmaEmbed" />
    <h2 {...{
      "id": "looking-for-icons",
      "style": {
        "position": "relative"
      }
    }}>{`Looking for icons?`}</h2>
    <ul>
      <li parentName="ul">{`Designers can browse and discover icons in our
`}<a parentName="li" {...{
          "href": "https://www.figma.com/file/uQuolYS7f1FqHH4UxSY88k/2.-Foundations-Icons?node-id=30%3A2"
        }}>{`Figma Design Toolkit`}</a>{`.`}</li>
      <li parentName="ul">{`Developers can find icons in our
`}<a parentName="li" {...{
          "href": "/components/icon/icons"
        }}>{`Chameleon icon search`}</a>{`.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      